import { AbstractComponent } from '@components/generic/abstract.component';
import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { TransactionResource } from '@components/order/resources/transaction.resource';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractResource } from '@resources/abstract.resource';
import { IRefundFormValue, IGrcReason } from '@components/order/interfaces/order-refund.interface';
import { FormNotifierService } from '@services/form-notifier.service';
import { SnackbarService } from '@components/snackbar';
import { GrcReasonResource } from '@components/order/resources/grc-reason-resource.service';
import { SessionHelper } from '@helpers/session.helper';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants/form';
import { NumberHelper } from '@helpers/NumberHelper';
import { IRefunds } from '@components/transactions';
import * as moment from 'moment';
import { DATE_SHORT_FORMAT } from '@constants/date';
import { OrderResource } from '@resources';
import { takeUntil } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import { LOCALE_FR } from '@constants';
import {TransactionsOrderItemsResource} from '@components/order/resources/transactions-order-items.resource';
import {HydraHelper} from '@helpers/HydraHelper';

export const REFUND_TYPE_FULL = 'full';
export const REFUND_TYPE_PARTIAL = 'partial';
export const REFUND_TYPE_COMMERCIAL = 'commercial';
export const DEBIT_OPERATION = 'debit';
export const CREDIT_OPERATION = 'credit';

@Component({
  selector: 'app-order-refund',
  template: require('./order-refund.component.html'),
  styles: [require('./order-refund.component.scss')],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: AbstractResource, useClass: TransactionResource },
    GrcReasonResource, TransactionResource, TransactionsOrderItemsResource
  ],
})
export class OrderRefundComponent extends AbstractComponent implements OnInit {

  public selectedQuantities: any[] = [];
  public selectedQuantity: number;
  public quantityList: number[] = [];
  public form: FormGroup;
  public reasons: { label: string; id: string }[];
  public grcReasons:  { label: string; id: string }[];
  public transactions: IRefunds[] = [];
  public transactionsCredit: IRefunds[] = [];
  public displayFieldsIban: boolean = false;
  public refundTypes: { label: string; id: string }[];
  public statusTransactionList: { label: string, value: string }[] = [];
  public showOrderItemSelectionError: boolean = false;
  public allItemsSelected: boolean = false;
  public reasonMapping: any = {
    'transport' : 'TRANSPORT',
    'supplier' : 'FOURNISSEUR',
    'customer' : 'CUSTOMER'
  };
  public orderItemsRefundable: Array<{ id: string; alreadyRefund: boolean }> = [];

  @Input() public order: any;
  @Input() public refundIdToEdit: string;

  @Output() private onCancelRefund: EventEmitter<any> = new EventEmitter();
  @Output() private onSubmit: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialog: any,
    private formNotifier: FormNotifierService,
    private snackbar: SnackbarService,
    private grcReasonResource: GrcReasonResource,
    private transactionResource: TransactionResource,
    private orderResource: OrderResource,
    private transactionsOrderItemsResource: TransactionsOrderItemsResource,
    private currencyPipe: CurrencyPipe,
  ) {
    super($translate, authService, resource, state);

    this.refundTypes = [
      { label: this.translate('PAGE.ORDER.EDIT.CREATE_REFUND.FIELDS.TYPES.FULL'), id: REFUND_TYPE_FULL },
      { label: this.translate('PAGE.ORDER.EDIT.CREATE_REFUND.FIELDS.TYPES.PARTIAL'), id: REFUND_TYPE_PARTIAL },
      { label: this.translate('PAGE.ORDER.EDIT.CREATE_REFUND.FIELDS.TYPES.COMMERCIAL'), id: REFUND_TYPE_COMMERCIAL },
    ];

    this.statusTransactionList = [
      { label: this.translate('PAGE.REFUNDS.LIST.STATUSES.WAITING'), value: 'waiting' },
      { label: this.translate('PAGE.REFUNDS.LIST.STATUSES.WAITING_ELEMENTS'), value: 'elements_waiting' },
      { label: this.translate('PAGE.REFUNDS.LIST.STATUSES.WAITING_SAGE'), value: 'sage_waiting' },
      { label: this.translate('PAGE.REFUNDS.LIST.STATUSES.CLOSE'), value: 'close' },
      { label: this.translate('PAGE.REFUNDS.LIST.STATUSES.ERROR'), value: 'error' },
      { label: this.translate('PAGE.REFUNDS.LIST.STATUSES.TO_CHECK'), value: 'to_check' },
    ];
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    if (!this.order) {
      this.fetchOrder();
      return;
    }
    this.buildForm();
    this.getTransactionsHistory();
    this.getTransactionsCredit();
  }

  private fetchOrder(): void {
    (<OrderResource>this.orderResource).getOrderManagerOrder(this.state.params.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.order = response;
        this.displayFieldsIban = response.isRefundableViaBankWire || false;
        this.fillOrderItemRefundable(response);
        this.buildForm();
        this.getTransactionsHistory();
        this.getTransactionsCredit();
      })
      ;
  }


  private fillOrderItemRefundable(response: any): void {

    response.items.forEach((item: any) => {
      const existing = this.orderItemsRefundable.find(refund => refund.id === item.id);
      const isRefunded = item.refund && item.nbRefund >= item.quantity;

      if (isRefunded === true) {
        item.checked = false;
      }

      if (existing) {
        existing.alreadyRefund = isRefunded;
      } else {
        this.orderItemsRefundable.push({id: item.id, alreadyRefund: isRefunded});
      }
    });
  }

  public isAlreadyRefund(item: any): boolean {
    const existing = this.orderItemsRefundable.find(refund => refund.id === item.id);
    return existing.alreadyRefund;
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      type: [{ label: this.translate('PAGE.ORDER.EDIT.CREATE_REFUND.FIELDS.TYPES.FULL'), id: REFUND_TYPE_FULL }, Validators.required],
      amount: [0, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.required]],
      marketplaceInitiative: [false],
      reason: [null, Validators.required],
      grcReason: [null, Validators.required],
      comment: [''],
      iban: [''],
      bic: [''],
      file: [''],
      zendeskTicketId: [''],
    });

    this.grcReasonResource.cGet({'exists[parent]': false, 'active': 1}, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: IGrcReason[]) => {

        this.reasons = response.map((reason: IGrcReason) => {

          return {
            label: (reason.translations[SessionHelper.getUILanguage()] || reason.translations[LOCALE_FR]).reason,
            id: reason['@id'],
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });

        this.form.patchValue({ 'reason': null });
        if (this.state.params.refund && this.state.params.reason) {
          this.form.get('reason').patchValue(this.reasons.find((label) => label.label === this.reasonMapping[this.state.params.reason]));
        }
      })
    ;

    this.grcReasonResource.cGet({'exists[parent]': true, 'active': 1, 'refund': 1}, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: IGrcReason[]) => {

        this.grcReasons = response.map((grcReasons: IGrcReason) => {
          return {
            label: (grcReasons.translations[SessionHelper.getUILanguage()] || grcReasons.translations[LOCALE_FR]).reason,
            id: grcReasons['@id'],
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });
        this.form.patchValue({ 'grcReasons': null });
      })
    ;

    if (this.state.params.refund) {
      switch (this.state.params.refund) {
        case 'total':
          this.form.get('type').patchValue(this.refundTypes[0]);
          this.form.get('amount').patchValue(this.removeInterestCdiscountOrder(this.getTotalOrderAmount()));

          break;
        case 'partial':
          this.form.get('type').patchValue(this.refundTypes[1]);
          break;
      }

    }
  }

  public submit(): void {
    if (this.getOrderItemsChecked().length === 0 && undefined === this.getRefund()) {
      this.showOrderItemSelectionError = true;
      this.snackbar.alert(this.translate('PAGE.ORDER.EDIT.CREATE_REFUND.ERROR_ORDER_ITEMS'));
      return;
    }

    this.dialog.confirm(this.translate('PAGE.ORDER.EDIT.CREATE_REFUND.CONFIRM_CREATE'))
      .then(() => {
        const formData: FormData = this.prepareBody();
        const options: any = {};

        if (this.getRefund()) {
          options['entryPoint'] = `/v2/transactions/${this.getRefund().id}`;
        }

        this.resource.uploadFile(formData, options)
          .takeUntil(this.destroyed$)
          .subscribe((response: any) => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.formNotifier.notifyFormSubmitted();
            this.onSubmit.emit();

            this.order.items.forEach((item: any) => {
              if (item.checked) {
                const quantity = +(item.selectedQuantity ? item.selectedQuantity : (item.quantity ? item.quantity : 1));

                this.transactionsOrderItemsResource.createTransactionOrderItem({
                  orderItem: HydraHelper.buildIri(item.id, 'order_items'),
                  transaction: HydraHelper.buildIri(response.id, 'transactions'),
                  quantity: quantity
                }).toPromise()
                  .then(() => {
                  });
              }
            });
          })
          ;
      })
      ;
  }

  private prepareBody(): FormData {
    const formValue: IRefundFormValue = this.form.value;
    let file: File;

    if (undefined !== (<HTMLInputElement & EventTarget>formValue.file.target) &&
      (<HTMLInputElement & EventTarget>formValue.file.target).files.length > 0) {
      file = (<HTMLInputElement & EventTarget>formValue.file.target).files[0];
    }
    const formData = new FormData();

    if (undefined !== this.getRefund()) {
      formData.append('comment', formValue.comment);
      formData.append('iban', formValue.iban);
      formData.append('bic', formValue.bic);
      formData.append('file', file);

      return formData;
    }

    formData.append('type', formValue.type.id);
    formData.append('amount', '' + NumberHelper.parseFloat(formValue.amount));
    if (!!formValue.marketplaceInitiative) {
      formData.append('marketplaceInitiative', 'true');
    }

    if (this.grcReasons && this.grcReasons.length > 0) {
      formData.append('grcReason', formValue.grcReason['id'].split('/').pop());
    } else {
      formData.append('grcReason', formValue.grcReason['id'].split('/').pop());
    }

    formData.append('comment', formValue.comment);
    formData.append('iban', formValue.iban);
    formData.append('bic', formValue.bic);
    formData.append('file', file);
    formData.append('operation', DEBIT_OPERATION);
    formData.append('order', this.order.id);
    formData.append('zendeskTicketId', formValue.zendeskTicketId);

    return formData;
  }

  public onSelectQuantity(item: any): void {
    this.quantityList = [null];

    for (let i = 1; i <= item.quantity; i++) {
      this.quantityList.push(i);
    }
  }

  public onChangeQuantity(item: any, selectedQuantity: number): void {
    if (this.isAlreadyRefund(item) === true && REFUND_TYPE_COMMERCIAL !== this.form.get('type').value.id) {
      return;
    }

    const index = this.order.items.indexOf(item);
    if (index !== -1) {
      // @ts-ignore
      if ('null' === selectedQuantity) {
        this.selectedQuantities[index] = null;
      } else {
        this.selectedQuantities[index] = selectedQuantity;
        item.selectedQuantity = selectedQuantity;
      }
    }

    item.checked = +item.quantity >= this.selectedQuantities[item.id];
  }

  public onSelectOrder(): void {
    this.order.items.forEach((item: any, index: number) => {
      const selectedQuantity = this.selectedQuantities[index];

      if (this.isAlreadyRefund(item) === true) {
       return;
      }

      if (item.quantity > 1 && (undefined === selectedQuantity || 'null' === selectedQuantity)) {
          this.onChangeQuantity(item, item.quantity);
      }

      item.checked = this.allItemsSelected;
    });

    const items: any[] = [];
    this.getOrderItemsChecked().forEach((itemChecked: any) => {
      if (itemChecked.quantity === 1) {
        itemChecked.selectedQuantity = itemChecked.quantity;
      }
      items.push(itemChecked.id + '/' + itemChecked.selectedQuantity);
    });

    this.resource.getTransactionAmount(this.state.params.id, items).takeUntil(this.destroyed$)
      .subscribe((totalRefundable: any) => {
        this.form.get('amount').patchValue(this.removeInterestCdiscountOrder(totalRefundable));
      });
  }

  public canRefundItem(event: any): void {
    // can't be computed through guessers because the transaction does not exist...
    if ('bol' === this.order.marketplace) {
      this.displayFieldsIban = REFUND_TYPE_FULL !== event.id;
    }

    if (REFUND_TYPE_COMMERCIAL === event.id) {
      this.orderItemsRefundable.forEach(refund => {
        refund.alreadyRefund = false;
      });
    } else {
      this.fillOrderItemRefundable(this.order);
    }

    this.order.items.forEach((e: any) => {
      this.onSelectOrderItem(e);
    });
  }

  public onSelectOrderItem(item: any = null, selectedQuantity: any = null): void {
    if (item.checked && item.quantity > 1 && ('null' === selectedQuantity || null === selectedQuantity)) {
      this.snackbar.alert(this.translate('PAGE.ORDER.EDIT.CREATE_REFUND.QUANTITY_ALERT', {sku: item.product.sku}));
    }

    if (this.getOrderItemsChecked().length === 0 && ('null' === selectedQuantity || null === selectedQuantity) && undefined === this.getRefund()) {
      this.form.get('amount').patchValue(0);
      this.showOrderItemSelectionError = true;
      this.allItemsSelected = false;
    } else {
      this.showOrderItemSelectionError = false;
    }

    if (REFUND_TYPE_COMMERCIAL !== this.form.get('type').value.id) {
      if (this.getOrderItemsChecked().length === this.order.items.length) {
        if (selectedQuantity && item.quantity > selectedQuantity) {
          this.form.get('type').patchValue(this.refundTypes.find((type) => type.id === REFUND_TYPE_PARTIAL));
        } else {
          this.form.get('type').patchValue(this.refundTypes.find((type) => type.id === REFUND_TYPE_FULL));
        }
      } else if (this.getOrderItemsChecked().length > 0 || item.quantity > selectedQuantity) {
        this.form.get('type').patchValue(this.refundTypes.find((type) => type.id === REFUND_TYPE_PARTIAL));
      } else {
        this.form.get('type').patchValue(this.refundTypes.find((type) => type.id === REFUND_TYPE_COMMERCIAL));
      }
    }

    const items: any[] = [];
    this.getOrderItemsChecked().forEach((itemChecked: any) => {
      if (itemChecked.quantity === 1) {
        itemChecked.selectedQuantity = itemChecked.quantity;
      }
      items.push(itemChecked.id + '/' + itemChecked.selectedQuantity);
    });

    this.resource.getTransactionAmount(this.state.params.id, items).takeUntil(this.destroyed$)
      .subscribe((totalRefundable: any) => {
        this.form.get('amount').patchValue(this.removeInterestCdiscountOrder(totalRefundable));
      });
  }

  public hasFreeShipping(): boolean {
    if (this.hasDiscountCart(this.order)) {
      return this.order.discountDetail.carts.some((discount: any) => discount.freeShipping);
    }

    return false;
  }

  public addDiscount(item: any): number {
    let totalDiscount: number = null;
    let totalQuantity: number = null;
    const quantity = item.selectedQuantity ? item.selectedQuantity : (item.quantity ? item.quantity : 1);

    // tslint:disable-next-line:no-shadowed-variable
    this.order.items.forEach((item: any): void => {
      totalQuantity += item.quantity;
    });

    if (this.hasDiscountProduct(this.order)) {
      this.order.discountDetail.products.forEach((product: any): void => {
        if (product && product.sku === item.product.sku) {
          totalDiscount += product.discount * quantity;
        }
      });
    }

    if (this.hasDiscountCart(this.order)) {
      this.order.discountDetail.carts.forEach((discount: any): void => {
        if (discount.discountCode && !discount.discountCode.startsWith('KDO-')) {
          const value = discount.discount.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
          totalDiscount += +(quantity / totalQuantity * value).toFixed(2);
        }
      });
    }

    return totalDiscount;
  }
  public OnSelectReason(event: any) {
    this.grcReasonResource.cGet({'parent': event.id.split('/').pop(), 'refund': 1}, { returnHydraMembers: true, blocking: true })
    .takeUntil(this.destroyed$)
    .subscribe((response: IGrcReason[]) => {

      this.grcReasons = response.map((reason: IGrcReason) => {
        return {
          label: (reason.translations[SessionHelper.getUILanguage()] || reason.translations[LOCALE_FR]).reason,
          id: reason['@id'],
        };
      }).sort((a, b) => {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }
        return 0;
      });

      this.grcReasons  = this.grcReasons.filter(elm => elm);

      if (this.grcReasons.length === 0) {
        this.form.controls['grcReason'].clearValidators();
        this.form.controls['grcReason'].updateValueAndValidity();
      } else {
        this.form.patchValue({ 'grcReason': null });
        this.form.controls['grcReason'].setValidators(Validators.required);
        this.form.controls['grcReason'].updateValueAndValidity();
      }
    })
    ;
  }

  public cancel(): void {
    this.onCancelRefund.emit();
  }

  public getOrderItemsChecked() {
    return this.order.items.filter((i: any) => i.checked === true);
  }

  public getTotalServiceAmount(): number {
    return this.order.items
      .map((item: any) => item.totalServiceAmount)
      .reduce((accumulator: number, currentValue: number) => accumulator + currentValue)
      ;
  }

  private getTotalShippingPrice(): number {
      const itemShippingPrices = this.order.items.reduce((total: number, item: any) =>
        total + item.shippingPrice, 0);

      if (itemShippingPrices === this.order.shippingPriceOrder && this.order.shippingFeeAll === 0) {
        return itemShippingPrices;
      }

      return itemShippingPrices + this.order.shippingPriceOrder;
  }

  public getTotalDiscount(): number {
    return (0 === this.order.discountDetail.length ? 0 : this.order.fullStatusDiscount) + this.order.items
      .map((item: any) => item.discount)
      .reduce((accumulator: number, currentValue: number) => accumulator + currentValue)
      ;
  }

  public getTotalOrderAmount(): number {
    return this.getTotalShippingPrice() + this.order.interest + this.order.items.map((item: any) => {
      return item.unitPrice * item.quantity + item.ecotax * item.quantity + item.deee * item.quantity;
    })
      .reduce((accumulator: number, currentValue: number) => accumulator + currentValue)
      ;
  }

  public getRefundType(type: string): string[] {
    return this.refundTypes.filter(rt => rt.id === type).map(function (t) {
      return t.label;
    });
  }

  public getRefundStatus(status: string): string[] {
    return this.statusTransactionList.filter(rs => rs.value === status).map(function (s) {
      return s.label;
    });
  }

  public getDate(date: string) {
    return moment(date).format(DATE_SHORT_FORMAT);
  }

  private getTransactionsHistory() {
    const params = { ...this.getParams(), 'order.orderId': this.order.orderId };

    this.transactionResource.cGet(params, { isHydra: true, returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: IRefunds[]) => {
        this.transactions = response;
        this.fillForm();
      })
      ;
  }

  private getTransactionsCredit() {
    const params = { ...this.getParams(), 'order.orderId': this.order.orderId, 'operation': CREDIT_OPERATION };

    this.transactionResource.cGet(params, { isHydra: true, returnHydraMembers: true, blocking: false})
      .takeUntil(this.destroyed$)
      .subscribe((response: IRefunds[]) => {
        this.transactionsCredit = response;
      });
  }

  public getItemTransactionAmount(item: any, isTotalAmount: boolean = false): number {
    if (item.alreadyRefund === true) {
      return ;
    }
    let quantity: number;
    if (item.selectedQuantity && !isTotalAmount) {
      quantity = item.selectedQuantity;
    } else {
      quantity = item.quantity;
    }

    return item.unitPrice * quantity + item.ecotax * quantity + item.deee * quantity + item.totalServiceAmount;
  }

  public getTotalTransactionAmount(): number {
    return this.transactions.map((transaction) => {
      return transaction.amount;
    })
      .reduce((accumulator: number, currentValue: number) => accumulator + currentValue)
      ;
  }

  private getParams(): object {
    return { ...this.resource.cGetDefaultFilters() };
  }

  /**
   * Does the list of "credit" Transactions contain items with any of the given "providers" ?
   */
  public transactionsListContains(providers: string[] = []): boolean {
    let contains = false;

    this.transactionsCredit.forEach((item: IRefunds) => {
      const provider = item.provider;

      if (providers.indexOf(provider) > -1) {
        contains = true;

        return true;
      }
    });

    return contains;
  }

  /**
   * An order has a gift card if it has a discount_cart with a code that starts with "KDO-".
   */
  public hasGiftCard(order: any): boolean {
    let has = false;

    const discountsDetail = order.discountDetail;

    if (discountsDetail && discountsDetail.carts) {
      discountsDetail.carts.forEach((cart: any) => {
        if (cart.discountCode && cart.discountCode.startsWith('KDO-')) {
          has = true;

          return true;
        }
      });
    }

    return has;
  }

  public hasDiscountProduct(order: any): boolean {
    const discountsDetail = order.discountDetail;

    if (discountsDetail && discountsDetail.products) {
      return discountsDetail.products.length > 0;
    }

    return false;
  }

  public hasDiscountCart(order: any): boolean {
    const discountsDetail = order.discountDetail;

    if (discountsDetail && discountsDetail.carts) {
      const notGiftCards = discountsDetail.carts.filter((cart: any) => {
        return cart.discountCode && !cart.discountCode.startsWith('KDO-');
      });

      return notGiftCards.length > 0;
    }

    return false;
  }

  public hasDiscountCredit(order: any): boolean {
    const discountsDetail = order.discountDetail;

    if (discountsDetail && discountsDetail.credits) {
      return discountsDetail.credits.length > 0;
    }

    return false;
  }
  public getDiscountsDetail(order: any): string {
    const discounts = {'DISCOUNT': 0};

    if (this.hasDiscountProduct(order)) {
      order.discountDetail.products.forEach((discount: any) => {
        discounts.DISCOUNT += discount.totalDiscount;
      });
    }

    if (this.hasDiscountCart(order)) {
      order.discountDetail.carts.forEach((discount: any) => {
        if (discount.discountCode && !discount.discountCode.startsWith('KDO-')) {
          discounts.DISCOUNT += discount.discount;
        }
      });
    }

    return this.createStringResult(discounts);
  }

  public getTransactionsDetail(): string {
    const transactions: { [key: string]: number } = {'CREDIT_CARD': 0, 'BANKWIRE': 0, 'PAYPAL': 0, 'CASH': 0, 'MARKETPLACE': 0};

    this.transactionsCredit.forEach((transaction: IRefunds) => {
      switch (transaction.provider) {
        case 'credit_card':
          transactions.CREDIT_CARD += transaction.amount;
          break;
        case 'bankwire':
          transactions.BANKWIRE += transaction.amount;
          break;
        case 'paypal':
          transactions.PAYPAL += transaction.amount;
          break;
        case 'cash':
          transactions.CASH += transaction.amount;
          break;
        case 'marketplace':
          transactions.MARKETPLACE += transaction.amount;
          break;
      }
    });

    return this.createStringResult(transactions);
  }

  public getCreditsOrGiftCardsDetail(order: any): string {
    const list = {'CREDIT': 0, 'GIFT_CARD': 0};

    if (this.transactionsListContains(['credit'])) {
      this.transactionsCredit.forEach((transaction: IRefunds) => {
        if ('credit' === transaction.provider) {
          list.CREDIT += transaction.amount;
        }
      });
    }

    if (this.hasGiftCard(order)) {
      const discountsDetail = order.discountDetail;

      if (discountsDetail && discountsDetail.carts) {
        discountsDetail.carts.forEach((cart: any) => {
          if (cart.discountCode.startsWith('KDO-')) {
            list.GIFT_CARD += cart.discount;
          }
        });
      }
    }

    return this.createStringResult(list);
  }

  private createStringResult(list: any): string {
    let result = '';

    for (const [key, value] of Object.entries(list)) {
      if (value > 0) {
        const currencyValue = this.currencyPipe.transform(
          value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0],
          this.currency,
          'symbol',
          '1.2-6',
          this.shortLocale
        );
        let label: string = this.$translate.instant(`PAGE.REFUNDS.PROVIDER.${key}`);

        if (key === 'bcmc') {
           label = 'BANCONTACT';
        } else if (key === 'ideal') {
           label = 'IDEAL';
        }

        result += label + ': ' + currencyValue + ' - ';
      }
    }

    return result.slice(0, -3);
  }

  public getRefund(): IRefunds | null {
    if (undefined === this.refundIdToEdit) {
      return;
    }

    return this.transactions.find((transaction: IRefunds) => transaction['@id'] === this.refundIdToEdit);
  }
  public removeInterestCdiscountOrder(totalAmount: number) {
    if (this.order.marketplace !== 'cdiscount') {
      return totalAmount;
    }

    return totalAmount - this.order.interest;
  }

  private fillForm(): void {
    const refund: any = this.getRefund();

    if (undefined === refund) {
      return;
    }

    this.form.patchValue({
      type: { label: this.translate('PAGE.ORDER.EDIT.CREATE_REFUND.FIELDS.TYPES.FULL'), id: refund.type },
      amount: refund.amount,
      marketplaceInitiative: refund.marketplaceInitiative,
      reason: { label: refund.grcReason.parent.translations[SessionHelper.getUILanguage()].reason, id: refund.grcReason.parent['@id'] },
      grcReason: { label: refund.grcReason.translations[SessionHelper.getUILanguage()].reason, id: refund.grcReason['@id'] },
      comment: refund.comment,
      iban: refund.iban,
      bic: refund.bic,
      zendeskTicketId: refund.zendeskTicketId,
    });
  }
}
